.module-footer {
	height: 60px;
	background-color: #262523;
	font-family: $font-1;
	.div {
		border-right: 1px solid #909090;
		margin-top: 20px;
	}
	.left {
		color: #909090;
    font-size: 14px;
		float: right;
		padding: 0 15px;
		height: 15px;
		margin-top: 5px;
	}
	.right {
		float: left;
		padding: 0px;
		height: 15px;
		margin: 15px 0 0 25px;
		a {
			color: #909090;
			font-size: 14px;
		}
		img {	vertical-align: text-bottom;}
	}
	.footer-padding {	padding: 0;}
		@media screen and (max-width: 768px) {
			text-align: center;
			padding-top: 15px;
			height: 90px;
			.right {float: none;}
			.left {
				padding: 0;
				float: none;
				border: 0;
			}
		}
}
