@import 'fonts';
// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';
@import 'addons/font-awesome/font-awesome';
//@import 'addons/owl-carousel2/owl.carousel';
@import 'addons/layerslider/layerslider';
@import 'addons/magnific-popup/magnific-popup';

//@import 'addons/cube-portfolio/cubeportfolio';

// IMPORT TEMPLATE
@import 'functions';
@import 'flex';
/*****************************************************/

// FONTS
$font-1: "Nunito";
$font-2: "Libre Baskerville";


//COLOR
$color-red: #d7171e;

$color-blue: #1d60ae;

$color-text-gris: #b6b6b6;

$color-white: #fff;
/*****************************************************/

/* GENERAL TEMPLATE */
body {
	color: #000000;
	font-size: 10px;
	font-weight: 400;
	font-family: $font-1;
	line-height: 100%;
	margin-top: 115px;
	a {
		text-decoration: none !important;
		&:hover, &:focus {
			text-decoration: none !important;
		}
	}


	h1.referencement {
		display: none !important;
	}
	.appear {
		opacity: 0;
		@include transition(opacity 0.75s ease-in);
	}
	.parallax, .parallax-slow, .parallax-fast {
		background-attachment: fixed;
		@media screen and (max-width: 768px) {
			background-attachment: scroll;
		}
	}
	@media screen and (max-width: 1200px) {
		margin-top: 95px;
	}
	@media screen and (max-width: 1310px) {
		margin-top: 80px;
	}
}

/*****************************************************/
/*****************************************************/

/*****************************************************/
.vissible-xs-only{
	display: none;
	@media screen and (max-width: 767px) {
		display: block;
	}
}
.no-padding {padding: 0;}
/***********mixins*************************************/
@mixin text-style ($size, $color, $family, $weight) {
	color: $color;
	font-size: $size;
	font-family: $family;
	font-weight: $weight;
}
@mixin top-style {
	display: flex;
	.left-img, .right-img {
		width: 50%;
		img{min-width: 100%;}
	}
	@media screen and (max-width: 767px) {
		.left-img {width: 100%}
		.right-img {display: none;}
	}
}
/*****************************************************/
@import 'menu';
/************menu movile***************************/

/*************************************************************************************/
.slideshow-wrap {
	position: relative;
	.caption {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 9;
		h3 {
			text-align: center;
			text-transform: uppercase;
			font-size: 4vw;
			font-weight: 300;
			font-family: $font-1;
			color: white;
		}
		@media screen and (max-width: 1200px) {
			left: 50%;
		}
	}
	.ls-bottom-nav-wrapper {
		//z-index: 999
		display: block !important;
		width: 100%;
		bottom: 10%;
		height: 11px;
		position: absolute;
		text-align: center;
		@include transform(translateY(-50%));
		.ls-bottom-slidebuttons {
			top: 0;
			a {
				visibility: visible;
				width: 15px;
				height: 15px;
				margin: 0 30px;
				position: relative;
				border: 2px solid #000000!important;
				border-radius: 100%;
				display: inline-block;
				background-color: #fff;
				&.ls-nav-active {
					background: $color-blue;
				}
			}
			@media screen and (max-width: 767px) {
				display: none;
			}
		}
	}
}
/************************************************************************************/
.section01-index {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url('../images/body_section_01_bg.jpg');
	padding: 40px 0;
	img {margin: 0 auto;}
	h3 {
		@include text-style(30px,$color-blue,$font-1,900);
		border-bottom: 4px solid $color-blue;
		text-align: center;
		padding-bottom: 20px;
		text-transform: uppercase;
	}
	p {
		@include text-style(30px,#fff,$font-1,400);
		padding: 0;
		margin: 0;
		text-align: center;
		line-height: 1.5;
		margin: 0 auto;
		text-transform: uppercase;
	}
	.text-left {border-right: 2px solid $color-blue;}
	.text-right {
		border-left: 2px solid $color-blue;
	}
	@media screen and (max-width: 991px) {
		//background-image: url('../images/body_section_01_bg_m.jpg');
		p, h3 {font-size: 20px;}
	}
	@media screen and (max-width: 767px) {
		//background-image: url('../images/body_section_01_bg_m.jpg');

		.text-left {
			border-right: none;
		}
		.text-right {
			border-left: none;
		}
	}
}

/**********************************************************************************************/
.section02-index {
	padding: 0;
	display: flex;
	.left,.right {
		width: 50%;
	}
	.left {img {max-width: 100%;}}
	.right {
		align-items: center;
		display: flex;
		justify-content: space-around;
		.text-box {
			max-width: 70%;
		}
		p {
			@include text-style(1.5vw, $color-text-gris ,$font-1 ,400 );
			line-height: 1.5;
			padding-bottom: 20px;
		}
		h3 {
			@include text-style(2vw, $color-blue ,$font-1 ,700 );
			line-height: 1.5;
		}
		ul {
			padding-left: 25px;

			li {
				@include text-style(1.5vw, $color-text-gris ,$font-1 ,400 );
				line-height: 1.5;
			}
		}
	}
	@media screen and (max-width: 767px){
		display: block;
		.left, .right {width: 100%;}
		.right {
			padding: 30px 0;
			.text-box {
				max-width: 95%;
			}
			h3 {
				font-size: 22px;
				text-align: center;
			}
			p {
				font-size: 20px;
				text-align: center;
			}
			ul {
				li {
					font-size: 20px;
				}
			}
		}
	}
}
.section03-index {
	@extend .section01-index;
	p {
		font-size: 24px;
		line-height: 2;
		text-transform: inherit;
	}
	@media screen and (max-width: 767px){
		p {font-size: 18px;}
	}
}
/******************************************************************************/
 @import "modules/burst";
/*******************module-footer*************************************/
 @import "modules/footer";


/*******module-header*****************************************************************/
 @import "modules/header";

  @import "modules/icons-text";

.service {
	.section02-index.left {
		.right {
			order: -1;
			.text-box {
				text-align: right;
				p {color: $color-blue;}
				ul {
					direction: rtl;
					li{color: $color-blue;}
				}
				@media screen and (max-width: 767px){
					text-align: left;
					ul {
						direction: ltr;
						width: 175px;
						margin: auto;
					}
				}
			}
		}
	}
}

/**coordonnees********************************************************************/
.coordonnees {
	.section01 {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-image: url('../images/body_section_01_bg.jpg');
		padding: 40px 0;
		text-align: center;
		h4 {
			@include text-style(35px, #d7171e ,$font-1 ,400 );
			line-height: 1.2;
		}
		a {
			text-align: center;
			@include text-style(26px, #fff ,$font-1 ,400 );
			line-height: 2;
			margin: 0 15px;
		}
		h3 {
			text-align: center;
			@include text-style(30px, #fff ,$font-1 ,700 );
			line-height: 1.2;
			padding-top: 20px;
			border-top: 2px solid #d7171e;
		}
		@media screen and (max-width: 767px) {
			h4, h3 {font-size: 18px;}
			a {
				font-size: 16px;
				display: block;
				&.sep {display: none;}
			}

		}
	}
}
