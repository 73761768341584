
/*****************************************************/
// MENU
.module-menu {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;

    .menu-desktop {
      background: $color-blue;
      height: 115px;
      border-bottom: 3px solid #fff;
      @include transition(all 0.25s ease);
      .menu-up {
        position: absolute;
        right: 5px;
        top: 20px;
        display: -webkit-flex;
        display: flex;
        list-style: none;
          a {
            display: flex;
            align-items: center;
            font-size: 20px;
            color: #fff;
            padding: 0 10px;
            font-weight: 500;
            text-transform: uppercase;
            white-space: nowrap;
            text-decoration: none;
            @include transition(all 0.25s ease);
            img {padding-right: 15px;}
          }
      }
      .main-nav {
        display: -webkit-flex;
        display: flex;
        list-style: none;
        align-items: baseline;
        margin-bottom: 0;
        padding: 0;
        @include transition(all 0.25s ease);
        li {
          a {
            font-size: 16px;
            color: #010000;
            font-weight: 600;
            display: block;
            text-transform: uppercase;
            white-space: nowrap;
            padding: 0px 15px 21px 15px;
            text-decoration: none;
            @include transition(all 0.25s ease);
          }
          &:first-child {
            padding: 25px 15px 0 15px;
            a {padding: 0;}
          }
          &:nth-child(2) {
            margin-right: auto;
            padding: 0 0 0 15px;
            margin-top: 25px;
            border-left: 2px solid $color-text-gris;
            a {padding: 0;}
          }
          &.item {
            &:hover {
              a{
                color: $color-text-gris;
              }

            }
            &.active {
              a {
                color: #fff;
                border-bottom: 4px solid #fff;
              }

            }
          }
          &.phone {
            img {padding-right: 10px;}
          }
          &.menu-toggle {
            display: none;
            height: 50px;
            width: 50px;
            margin-top: 45px;
            position: relative;
            z-index: 9999;
            margin-right: 5px;
            .bar {
              left: 50%;
              width: 25px;
              height: 3px;
              position: absolute;
              background: #fff;
              @include transition(all 0.25s ease-in);
              &:nth-child(1) {
                top: 33%;
                left: 50%;
                @include transform(translate(-50%,-50%));
              }
              &:nth-child(2) {
                top: 50%;
                left: 50%;
                @include transform(translate(-50%,-50%));
              }
              &:nth-child(3) {
                top: 66%;
                left: 50%;
                @include transform(translate(-50%,-50%));
              }
            }
            &.toggled {
                    .bar {
                        &:nth-child(1){
                            top: 50%;
                          @include transform(translate(-50%, -50%) rotate(45deg))
                }
                        &:nth-child(2){
                            opacity: 0;
                            @include transform(translate(-50%, -50%));
                }
                        &:nth-child(3){
                  top: 50%;
                  @include transform(translate(-50%, -50%) rotate(130deg));
                }
              }
            }
          }
        }
      }
      &.scrolled {
        //height: 100px;
        background: rgb(145, 198, 64);
        @include transition(all 0.25s ease);
        .main-nav {
          align-items: end;
          .item {
            margin-top: 70px;
            @include transition(all 0.25s ease);
            a {
              padding: 0 15px;
              @include transition(all 0.25s ease);
            }
          }
          .phone {
            margin-top: 15px;
            @include transition(all 0.25s ease);
          }
          .logo {
            width: 100px;
            padding: 0px;
          }
        }
      }
      @media screen and (max-width: 1310px) {
        height: 100px;
        .main-nav {
            align-items: end;
          .item{display: none;}
          li.menu-toggle {
            display: block;
          }
          .logo, .logo-right {
            width: 200px;
          }
        }
      }
      @media screen and (max-width: 670px) {
        height: 80px;
        .menu-up {display: none;}
        .main-nav {
          li.menu-toggle {
            margin-top: 15px!important;
          }
          li:first-child {padding: 15px 15px 15px 15px;}
          li:nth-child(2) {
            margin-top: 15px;
            padding-right: 15px;
          }
        }
      }
      @media screen and (max-width: 550px) {
        .main-nav {
          align-items: center;
          li:first-child, li:nth-child(2) {
            margin: auto;
            a {
              display: inherit;
              width: auto!important;
            }
          }
        }
      }
    }

  }



      /************ menu mobile ***************************/
      #menu-mobile {
          width: 100%;
          top: 0;
          right: -100%;
          z-index: 999;
          background: $color-blue;
          position: fixed;
          @include transition(all 0.3s ease-in);
          @media screen and (max-width: 1310px) {
              &.toggled {
                  overflow-y: auto;
                  bottom: 0; // pour avoir le scroll
                  @include transform(translate(-100%, 0%));
              }
          }
          .menu-toggle {
              float: right;
              margin: 15px 0 0 0;
          }
          ul {
              margin: 25px auto;
              padding: 30px;
              list-style: none;
              li {
                  // position: relative;
                  text-align: center;
                  line-height: 100%;
                  padding: 10px;
                  //border-bottom: 1px solid $color-2;
                  &:nth-last-child(1) {
                      border-bottom: none;
                  }
                  & > a, & > span.menu-title {
                      @include text-style(16px,#fff,$font-1,400);
                      color: #fff;
                      padding: 0 10px;
                  }
                  .submenu {
                      margin: 0px auto;
                      padding: 10px 0 10px;
                      ul {
                          margin: 0;
                          padding: 0;
                      }
                      li {
                          border-bottom: none;
                          padding-top: 10px;
                          text-align: left;
                          a {
                              @include text-style(16px,#fff,$font-1,400);
                              color: $color-blue;
                              font-weight: 400;

                          }
                          &:hover, &.active {
                              a {
                                color: $color-red;

                              }
                          }
                      }
                  }
                   &.active, &.phone {
                      a {
                        color: $color-red;
                        font-weight: 700;
                       }
                  }
                  & > a:hover{
                    color: $color-red;
                    font-weight: 700;
                  }
              }
          }
      }
