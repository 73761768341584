.module-icons-text {
  background-color: $color-white;
	padding: 40px 0;
	.item {
		@extend .flex;
    @extend .justify-between;
    @extend .items-center;
		padding: 40px 0;
		.icon-box {
			width: 30%;
		}
		.text-box {
			width: 70%;
			padding: 0 10px;
      p {
        @include text-style(18px, $color-blue ,$font-1 ,400 );
        line-height: 1.2;
      }
      h3 {
        @include text-style(25px, $color-blue ,$font-1 ,700 );
        line-height: 1.2;
        margin: 0;
      }
      @media screen and (max-width: 767px){
        p {font-size: 14px;}
        h3 {font-size: 20px;}
      }
		}
		&:nth-last-child(1){
			border-bottom: none;
		}
	}
}
