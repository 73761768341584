.burst-index {
	background-image: url('../images/burst_bg.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 70px 0;

	.burst-left,.burst-right {
		position: relative;
		@include transition(all 0.2s ease-in);
		border-radius: 15px;
		img.bg {
			position: relative;
			border-radius: 15px;
			min-width: 100%;
		}

		h4 {
			@include text-style(1.7vw,#fff,$font-1,700);
			position: absolute;
			text-align: center;
			text-transform: uppercase;
			top: 35px;
			width: 100%;
			padding: 0;
			margin: 0;
		}
		.icon {
			position: absolute;
			top: 35%;
			left: 50%;
			@include transform(translateX(-50%));
			width: 30%;
		}
	}
	.burst-left {
		box-shadow: 0 15px 35px rgba(0, 0, 0, 0.74);
		&:hover {
			box-shadow: -32px 1px 22px rgba(0, 0, 0, 0.74);
			@include transition(all 0.2s ease-in);
			@include transform(rotateY(-25deg)) ;
		}
	}
	.burst-right {
		box-shadow: 0 15px 35px rgba(0, 0, 0, 0.74);
		&:hover {
			box-shadow: 32px 1px 22px rgba(0, 0, 0, 0.74);
			@include transition(all 0.2s ease-in);
			@include transform(rotateY(25deg)) ;
		}
	}
	@media screen and (min-width: 1600px) {

		.burst-left,.burst-right {
			h4 {font-size: 28px;}
		}
	}
	@media screen and (max-width: 767px) {
		.burst-left,.burst-right {
			max-width: 500px;
			margin: 30px auto;
			h4 {font-size: 22px;}
		}
		.burst-left {margin-bottom: 30px;}
	}
	@media screen and (max-width: 500px) {
		.burst-left,.burst-right {
			h4 {
				font-size: 16px;
				top: 25px;
			}
		}
	}
}
